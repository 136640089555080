import { init as initSentry, makeBrowserOfflineTransport, makeFetchTransport, replayIntegration, setUser as setSentryUser, captureConsoleIntegration } from '@sentry/vue';
import { initGoogleAnalytics } from './googleAnalytics';
import { escapeRegex } from './helpers';

let sentryInitialized = false;
export function useLogging(app, $store, $router) {
	if ($store.environment === 'production' && !$store.debugging) {
		initGoogleAnalytics({ router: $router });
	}
	if ($store.environment !== 'development' && !$store.debugging) {
		const currentOrigin = window.location.origin.toLowerCase();
		if (sentryInitialized) return;
		sentryInitialized = true;
		initSentry({
			app: app,
			dsn: "https://03a53fda8f7d4d2080bacefcb0ad7a2e@o229809.ingest.sentry.io/6660614",
			environment: $store.environment,
			release: __APP_VERSION__,
			beforeBreadcrumb(breadcrumb) {
				let drop = false;
				// drop = drop || breadcrumb.category === "console";
				drop = drop || ((breadcrumb.category === "fetch" || breadcrumb.category === "xhr") && (new URL(breadcrumb.data.url, currentOrigin)).origin.toLowerCase() !== currentOrigin);
				return drop ? null : breadcrumb;
			},
			beforeSend(event, hint) {
				const e = hint.originalException;
				let drop = false;
				drop = drop || e instanceof DOMException && e.name === 'AbortError';
				drop = drop || e instanceof Response;
				drop = drop || (event.logger === 'console' && event.message === '[object Response]');
				return drop ? null : event;
			},
			allowUrls: [
				new RegExp('^' + escapeRegex(currentOrigin + '/'), 'i'),
			],
			integrations: [
				replayIntegration({
					maskAllText: true,
					blockAllMedia: true,
				}),
				captureConsoleIntegration({
					levels: ['error', 'warn'],
				}),
			],
			transport: makeBrowserOfflineTransport(makeFetchTransport),
			transportOptions: {
				maxQueueSize: 1000,
			},
			replaysSessionSampleRate: 0,
			replaysOnErrorSampleRate: 1,
		});
	}
}

export function setUser(user) {
	if (!sentryInitialized) return;
	const userInfo = {
		ip_address: '{{auto}}',
	};
	if (user && user.id > 0) {
		userInfo.email = user.emailAddress;
		userInfo.id = user.id;
		userInfo.ip_address = '{{auto}}';
		userInfo.username = user.name;
		if (user.companyId) {
			userInfo.companyId = user.companyId;
		}
	}
	setSentryUser(userInfo);
}
