import { useStore } from '@/store';
import { register } from 'register-service-worker';

export function registerSW() {
	if (import.meta.env.MODE === 'production') {
		register(`${import.meta.env.BASE_URL}service-worker.js`, {
			updated() {
				useStore().serviceWorkerUpdate = true;
			},
			error(error) {
				if (useStore().isOnline) {
					console.error('Error during service worker registration:', error)
				}
			}
		})
	}
}
